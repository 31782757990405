<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">合同中心</a>
          <i>></i>
          <a href="javascript:;" @click="goHerf">合同列表</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">查看日志</a>
        </span>
      </div>
      <div class="framePage-body">


        <div class="framePage-scroll">
          <div class="ovy-a">
            <h4>审核记录</h4>
            <br>
            <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                stripe
                :header-cell-style="tableHeader"
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  :index="indexMethod"
                  width="120"
                  fixed
              />
              <el-table-column
                  label="操作内容"
                  align="left"
                  show-overflow-tooltip
                  prop="auditType"
                  min-width="120px"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                        "FD_CONTRACT_AUDIT_TYPE",
                        scope.row.auditType
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                  label="操作人"
                  align="center"
                  show-overflow-tooltip
                  prop="fullname"
              />

              <el-table-column
                  label="操作日期"
                  align="center"
                  show-overflow-tooltip
                  prop="createTime"
              />
              <el-table-column
                  label="备注"
                  align="center"
                  show-overflow-tooltip
                  prop="remark"
              >
                <template slot-scope="scope">
                  {{scope.row.remark || '--'}}
                </template>
              </el-table-column>

              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
        />
      </div>
    </div>

  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "viewLog",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  data() {
    return {
      contractId: this.$route.query.contractId,
      // projectSource: this.$route.query.projectSource,
      // auditState: this.$route.query.auditState,
      // projectName: this.$route.query.projectName,

      pageNumIndex: "",
    };
  },
  created() {
    console.log(this.$route.query.contractId)
  },
  methods: {
    getData(pageNum = 1) {
      this.pageNumIndex = pageNum;
      // console.log(this.pageNumIndex);
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        contractId: this.contractId,
        // idcard: this.idcard,
        // userName: this.userName,
        // mobile: this.mobile,
        // allowPrint: this.allowPrint,
        // authentication: this.authenticationState,
      };
      this.pageNumIndex = params.pageNum;
      this.doFetch({
        url: "/biz/contractAudit/pageList",
        params,
        pageNum,
      });
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight = window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 3) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    goHerf() {
      this.$router.push({
        path: "/web/financialSettlement/contractManagement",
        query: {
          refresh: false,
        },
      });
    },
  },
  watch: {
    // $route: {
    //   handler: function (val, oldVal) {
    //     if (val.query.refresh) {
    //       this.contractId = val.query.contractId;
    //       this.getData(-1);
    //     } else {
    //       this.getData(val.query.pageNum);
    //     }
    //   },
    //   // 深度观察监听
    //   deep: true,
    // },
  },
};
</script>
<style lang="less" scoped></style>
